import React from "react";
import classNames from "classnames";
import RedditIcon from "@mui/icons-material/Reddit";

const FooterSocial = ({ className, ...props }) => {
  const classes = classNames("footer-social", className);

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <a
            href="https://www.reddit.com/r/radki/"
            target="_blank"
            rel="noreferrer"
          >
            <RedditIcon />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocial;
