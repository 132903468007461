import React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Link } from 'react-router-dom';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { resendEmailVerification } from '../../api/index';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const LoginForm = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const [status, setStatus] = useState(0);
  const navigate = useNavigate();

  const auth = useAuth();

  const {
    className,
    topOuterDivider,
    bottomOuterDivider,      
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...otherProps
  } = props;

  const outerClasses = classNames(
    'signin section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'signin-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: 'Welcome back.',
  };

  const resendEmail = async () => {
    const response = await resendEmailVerification({ email });
    if(response) {
      setMsg('Email verification resent.');
    }
  }

  const submit = async (event) => {
    // prevent browser reload/refresh
    event.preventDefault();

    try {
      const payload = await auth.login({ email, password });

      console.log(payload);

      if(payload.status !== 1) {
        setMsg(payload.message);
        setStatus(payload.status);
      } else {
        navigate('/');
      }
    } catch(err) {
      console.log(err);
    }
  }

  if(!auth) return;

  return (
    <section
      {...otherProps}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader tag="h1" data={sectionHeader} className="center-content" />
          <div className="tiles-wrap">
            <div className="tiles-item">
              <div className="tiles-item-inner">
                <form>
                  <fieldset>
                    <div className="mb-12">
                      <Input
                        type="email"
                        label="Email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        labelHidden
                        required />
                    </div>
                    <div className="mb-12">
                      <Input
                        type="password"
                        label="Password"
                        placeholder="Password"
                        labelHidden
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required />
                    </div>
                    <div className="mt-24 mb-32">
                      <Button color="primary" wide onClick={(event) => submit(event)}>Sign in</Button>
                    </div>
                    <div className="signin-footer mb-32">
                      <Link to="/recover-password/" className="func-link text-xs">Forgot password?</Link>
                    </div>
                  </fieldset>
                </form>
                <div className="signin-bottom has-top-divider">
                  {
                    msg?.length > 0 && (
                      <div className="pt-32 text-xs center-content text-color-low">
                        {msg}
                      </div>
                    )
                  }

                  {
                    status === 403 && (
                      <div className="pt-32 text-xs center-content text-color-low">
                        <Button color="primary" wide onClick={resendEmail}>Resend Verification Email</Button>
                      </div>
                    )
                  }
                  <div className="pt-32 text-xs center-content text-color-low">
                    Don't you have an account? <Link to="/signup/" className="func-link">Sign up</Link>
                  </div>
                  <div className="pt-32 text-xs center-content text-color-low">
                    <Link to="/" className="func-link text-xs">Home</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default LoginForm;