import SpaceBarIcon from "@mui/icons-material/SpaceBar";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import MenuIcon from "@mui/icons-material/Menu";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";

const HotKey = (props) => {
  if (props.spaceBar) {
    return <SpaceBarIcon data-testid="SpaceBarIcon" />;
  } else if (props.one) {
    return <LooksOneIcon data-testid="LooksOneIcon" />;
  } else if (props.two) {
    return <LooksTwoIcon data-testid="LooksTwoIcon" />;
  } else if (props.three) {
    return <Looks3Icon data-testid="Looks3Icon" />;
  } else if (props.four) {
    return <Looks4Icon data-testid="Looks4Icon" />;
  } else if (props.upArrow) {
    return <ArrowUpwardRoundedIcon data-testid="ArrowUpwardRoundedIcon" />;
  } else if (props.downArrow) {
    return <ArrowDownwardRoundedIcon data-testid="ArrowDownwardRoundedIcon" />;
  } else if (props.menuIcon) {
    return <MenuIcon data-testid="MenuIcon" />;
  } else if (props.studyIcon) {
    return <LocalLibraryIcon data-testid="LocalLibraryIcon" />;
  }
};

export default HotKey;
