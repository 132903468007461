const Video = (props) => {
  if (!props?.src) {
    return null;
  }

  return (
    <video
      data-testid="video-element"
      width="600"
      height="600"
      autoPlay
      loop
      muted
      style={{
        boxShadow: "0px 10px 15px rgb(0 , 0, 0)",
        borderRadius: "10px",
      }}
    >
      <source src={props.src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default Video;
