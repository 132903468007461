import * as React from "react";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

import Drawer from "@mui/material/Drawer";

import Categories from "./Categories";

import Button from "@mui/material/Button";

import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InfoIcon from "@mui/icons-material/Info";

import { useAuth } from "../hooks/useAuth";
import { useStudy } from "../hooks/useStudy";
import { useNavigate } from "react-router-dom";

import { getStartAndEndTimes } from "../utils/time";

import theme from "../utils/theme";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => {
  return {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  };
});

function Header(props) {
  const anchor = "left";
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const openStudyMenu = Boolean(anchorEl);
  const openSettingsMenu = Boolean(anchorE2);
  const auth = useAuth();
  const study = useStudy();
  const navigate = useNavigate();

  const [newCount, setNewCount] = React.useState(0);
  const [dueCount, setDueCount] = React.useState(0);
  const [overDueCount, setOverdueCount] = React.useState(0);

  const handleStudyMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsMenuClick = async (event) => {
    setAnchorE2(event.currentTarget);
  };

  const toStudy = async (option) => {
    handleStudyMenuClose();

    if (option === "stop") {
      study.stop();
      return;
    }

    await study.study(option);
  };

  const logOut = () => {
    handleSettingsMenuClose();
    auth.logout();
    navigate("/");
  };

  const handleStudyMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsMenuClose = () => {
    setAnchorE2(null);
  };

  useEffect(() => {
    count();
  }, [study, study.categories, study.flashcards, study.studying]);

  const count = () => {
    const keys = Object.keys(study.flashcards);
    const { startOfDay, endOfDay } = getStartAndEndTimes(new Date());
    let newCardsCount = 0;
    let dueCardsCount = 0;
    let overDueCardsCount = 0;

    for (let x = 0; x < keys.length; x++) {
      if (study.flashcards[keys[x]].active === false) continue;
      if (study.flashcards[keys[x]].due === null) {
        newCardsCount++;
      } else if (new Date(study.flashcards[keys[x]].due) < startOfDay) {
        overDueCardsCount++;
      } else if (new Date(study.flashcards[keys[x]].due) < endOfDay) {
        dueCardsCount++;
      }
    }

    setNewCount(newCardsCount);
    setDueCount(dueCardsCount);
    setOverdueCount(overDueCardsCount);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Drawer anchor={anchor} open={open} onClose={toggleDrawer}>
          <Categories
            open={open}
            setOpen={setOpen}
            toggleDrawer={toggleDrawer}
          />
        </Drawer>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" theme={theme}>
          <Toolbar sx={{ position: "relative" }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              disabled={study.studying}
              onClick={toggleDrawer}
              className="open_category_menu"
            >
              <MenuIcon />
            </IconButton>

            <Typography
              variant="h6"
              component="div"
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              Radki
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            <Stack direction="row" spacing={0}>
              <Button
                id="study-button"
                aria-controls={openStudyMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openStudyMenu ? "true" : undefined}
                onClick={handleStudyMenuClick}
              >
                <Box
                  display="flex"
                  color="white"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <LocalLibraryIcon color="inherit" />
                </Box>
              </Button>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openStudyMenu}
                onClose={handleStudyMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  disabled={study.studying}
                  onClick={() => {
                    toStudy("all", study.flashcards);
                  }}
                >
                  All ({newCount + dueCount + overDueCount})
                </MenuItem>
                <MenuItem
                  disabled={study.studying}
                  onClick={() => {
                    toStudy("new", study.flashcards);
                  }}
                >
                  New ({newCount})
                </MenuItem>
                <MenuItem
                  disabled={study.studying}
                  onClick={() => {
                    toStudy("due", study.flashcards);
                  }}
                >
                  Due ({dueCount})
                </MenuItem>
                <MenuItem
                  disabled={study.studying}
                  onClick={() => {
                    toStudy("overdue", study.flashcards);
                  }}
                >
                  Overdue ({overDueCount})
                </MenuItem>
                <MenuItem
                  disabled={!study.studying}
                  onClick={() => {
                    toStudy("stop", study.flashcards);
                  }}
                >
                  Stop
                </MenuItem>
              </Menu>
              <Button
                id="settings-button"
                aria-controls={openSettingsMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openSettingsMenu ? "true" : undefined}
                onClick={handleSettingsMenuClick}
              >
                <Box
                  display="flex"
                  color="white"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <SettingsIcon color="inherit" />
                </Box>
              </Button>

              <Menu
                id="basic-settings"
                anchorEl={anchorE2}
                open={openSettingsMenu}
                onClose={handleSettingsMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem sx={{ gap: 2 }} onClick={() => navigate("stats")}>
                  <QueryStatsIcon />
                  Stats
                </MenuItem>
                <MenuItem sx={{ gap: 2 }} onClick={() => navigate("/profile")}>
                  <AccountCircleIcon />
                  Profile
                </MenuItem>
                <MenuItem sx={{ gap: 2 }} onClick={() => navigate("/info")}>
                  <InfoIcon />
                  Info
                </MenuItem>
                <MenuItem sx={{ gap: 2 }} onClick={logOut}>
                  <LogoutIcon />
                  Logout
                </MenuItem>
              </Menu>
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}

export default Header;
