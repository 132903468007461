import { useState, useEffect } from "react";
import Label from "./label";
import { InputBase } from "@mui/material";
import "katex/dist/katex.min.css";
import "./style.css";

const Cloze = (props) => {
  const [input, setInput] = useState("");
  const [color, setColor] = useState("info");
  const front = props.front ? props.front : "";
  const back = props.back ? props.back : "";
  const maxLength =
    (front.length > back.length ? front.length : back.length) + 1;

  const onChange = (e) => {
    const correct = e.target.value === back;
    setInput(e.target.value);
    setColor(correct ? "success" : "info");
  };

  const showAnswer = () => {
    setInput(back);
    setColor("success");
  };

  const hideAnswer = () => {
    setInput("");
    setColor("info");
  };

  useEffect(() => {
    if (props.showAnswer) {
      showAnswer();
    } else {
      hideAnswer();
    }
  }, [props.showAnswer]);

  return (
    <Label color={color}>
      <InputBase
        color={color}
        placeholder={front}
        value={input}
        onChange={onChange}
        inputProps={{
          style: { textAlign: "center", width: `${maxLength}ch` },
        }}
      />
    </Label>
  );
};

export default Cloze;
