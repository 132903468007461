import CookieConsent from "react-cookie-consent";

const CookieConsentForm = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="myAwesomeCookieName2"
      style={{
        background: "#2B373B",
        borderRadius: "10px",
        marginBottom: "20px",
        position: "fixed",
        bottom: "20px",
        left: "50%",
        transform: "translateX(-50%)",
        maxWidth: "90%",
        width: "90%",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.5)",
        color: "#fff",
        textAlign: "center",
        overflow: "auto",
        whiteSpace: "normal",
        wordWrap: "break-word",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      buttonStyle={{
        color: "#fff",
        backgroundColor: "#4e503b",
        fontSize: "13px",
        borderRadius: "5px",
        padding: "10px 20px",
        border: "none",
        marginLeft: "10px",
      }}
      expires={150}
    >
      <div>
        This website uses cookies to enhance the user experience and provide
        additional functionality. By continuing to use our site, you consent to
        our use of cookies.
      </div>
    </CookieConsent>
  );
};

export default CookieConsentForm;
