const { www } = require('../../config.js').config;

const loadMissingFlashcards = async(_ids) => {
    try {
      const data = await fetch(`${www}/api/user/load-missing-flashcards`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : www,
        },
        body: JSON.stringify({ _ids })
      });
  
      const response = await data.json();
  
      return  response.data;
    } catch(err) {
      console.log(err);
      return { categories: [], flags: [], flashcards: []} 
    }
  }

  export {
    loadMissingFlashcards
  }