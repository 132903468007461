import { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import remarkDirective from "remark-directive";
import { visit } from "unist-util-visit";
import { h } from "hastscript";
import "katex/dist/katex.min.css";
import "./style.css";

import Image from "../Image";
import HotKey from "./HotKey";
import Video from "./Video";
import Cloze from "./Cloze";

function plugins(props) {
  return (tree) => {
    visit(tree, (node) => {
      if (
        node.type === "textDirective" ||
        node.type === "leafDirective" ||
        node.type === "containerDirective"
      ) {
        const data = node.data || (node.data = {});
        const hast = h(node.name, node.attributes);

        data.hName = hast.tagName;
        data.hProperties = hast.properties;
      }
    });
  };
}

/**
 * ImageDisplay component to display a series of images with optional captions and scrolling functionality.
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.series - The series of images to display.
 * @param {boolean} [props.thumbnail=false] - Whether to display images as thumbnails.
 * @param {boolean} [props.imageOnly=false] - Whether to display only the image.
 * @param {boolean} [props.captionOnly=false] - Whether to display only the caption.
 * @returns {JSX.Element} The rendered component.
 */

function MarkDown(props) {
  const components = useMemo(
    () => ({
      cloze: (data) => <Cloze {...props} {...data} />,
      img: (data) => <Image {...props} {...data} />,
      p: "p",
      hotkey: HotKey,
      video: (data) => <Video {...props} {...data} />,
    }),
    [props]
  );

  const plugins = () => (tree) => {
    visit(tree, (node) => {
      if (
        node &&
        (node.type === "textDirective" ||
          node.type === "leafDirective" ||
          node.type === "containerDirective")
      ) {
        const data = node.data || (node.data = {});
        const hast = h(node.name, node.attributes);

        data.hName = hast.tagName;
        data.hProperties = hast.properties;
      }
    });
  };

  return (
    <ReactMarkdown
      children={props.children}
      components={components}
      remarkPlugins={[remarkGfm, remarkMath, remarkDirective, plugins]}
      rehypePlugins={[rehypeKatex]}
    />
  );
}

export default MarkDown;
