import React, { useState, useEffect, useCallback } from "react";
import Markdown from "../MarkDown";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

const DisplayFlashcard = (props) => {
  const {
    seriesIndex,
    imageIndex,
    setImageIndex,
    setSeriesIndex,
    snippet,
    studying,
  } = props;

  const [imageHeight, setImageHeight] = useState(500);
  const [isHoveringImg, setIsHoveringImg] = useState(false);
  const [isHoveringScrollBar, setIsHoveringScrollBar] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const reset = useCallback(() => {
    setSeriesIndex(0);
    setImageIndex(0);
    setImageHeight(500);
    setIsHoveringImg(false);
    setIsHoveringScrollBar(false);
    setScrolling(false);
  }, [setSeriesIndex, setImageIndex]);

  useEffect(() => {
    if (snippet) {
      reset();
    }
  }, [snippet, reset]);

  if (!snippet) {
    return null;
  }

  return (
    <div className={studying ? "" : "blurr"}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        height="100%"
        sx={{ marginTop: "5%" }}
        paddingBottom={`${100}px`}
      >
        <Grid container spacing={0}>
          <Grid item xs={0} md={3} />
          <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
            <Box justifyContent="left" p={2}>
              <Markdown
                showAnswer={props.showAnswer}
                snippet={snippet}
                seriesIndex={seriesIndex}
                imageIndex={imageIndex}
                setSeriesIndex={setSeriesIndex}
                setImageIndex={setImageIndex}
                isHoveringImg={isHoveringImg}
                setIsHoveringImg={setIsHoveringImg}
                isHoveringScrollBar={isHoveringScrollBar}
                setIsHoveringScrollBar={setIsHoveringScrollBar}
                scrolling={scrolling}
                setScrolling={setScrolling}
                imageHeight={imageHeight}
                setImageHeight={setImageHeight}
              >
                {snippet?.front}
              </Markdown>
            </Box>
            <Box justifyContent="center" p={2}>
              <Divider style={{ width: "95%" }} />{" "}
              {/* Adjust the width as needed */}
            </Box>
            {props.showAnswer && (
              <Box justifyContent="left" p={2} sx={{ textAlign: "left" }}>
                <Markdown
                  showAnswer={props.showAnswer}
                  snippet={snippet}
                  seriesIndex={seriesIndex}
                  imageIndex={imageIndex}
                  setSeriesIndex={setSeriesIndex}
                  setImageIndex={setImageIndex}
                  isHoveringImg={isHoveringImg}
                  setIsHoveringImg={setIsHoveringImg}
                  isHoveringScrollBar={isHoveringScrollBar}
                  setIsHoveringScrollBar={setIsHoveringScrollBar}
                  scrolling={scrolling}
                  setScrolling={setScrolling}
                  imageHeight={imageHeight}
                  setImageHeight={setImageHeight}
                >
                  {snippet?.back}
                </Markdown>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default DisplayFlashcard;
