import React from "react";
import HeroSplit from "../components/sections/HeroSplit";
import FeaturesTiles from "../components/sections/FeaturesTiles";
// import FeaturesTabs from '../components/sections/FeaturesTabs';
// import News from '../components/sections/News';
// import Roadmap from '../components/sections/Roadmap';
// import Pricing from '../components/sections/Pricing';
// import Cta from '../components/sections/Cta';
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Header navPosition="right" />
        <HeroSplit hasBgColor invertColor />
        <FeaturesTiles />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
